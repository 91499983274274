<script>
import SelectorOption from '@/shared/components/SelectorOption.vue';

const store = {
  state: {
    vals: [],
  },
};
export default {
  name: 'SelectWithCheckBox',
  components: {
    SelectorOption,
  },
  props: {
    msg: String,
    options: {
      type: Array,
    },
    value: {
      type: Array,
    },
    updateVal: {
      type: Function,
    },
  },
  data() {
    return {
      store,
      sel: false,
    };
  },
  methods: {
    updateValue(opt, val) {
      store.state.vals = [...this.value];
      if (val === 'true') {
        store.state.vals.push(opt);
        this.updateVal(store.state.vals);
      } else {
        let index = 0;
        store.state.vals.forEach((v, i) => {
          if (v.value === opt.value) {
            index = i;
          }
        });

        store.state.vals.splice(index, 1);
        this.updateVal(store.state.vals);
      }
    },
  },
};
</script>
<template>
  <div class="select-with-check-box">
    <div
      v-for="option in options"
      :key="option.value + option.text">
      <SelectorOption
        :value="value"
        :option="option"
        :update-value="updateValue"
      />
    </div>
  </div>
</template>
<style>
.select-with-check-box{
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 8px;
  height: 100px;
  overflow-y: scroll;
}
.select-with-check-box .option-container{
  display: inline-flex;
}
</style>
