import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`TAGS.FIELDS.${name.toUpperCase()}`);

const fields = {
  departament: new Array([]),
  group: new Array([]),
  tag_name: new StringField('tag_name', label('tag_name'), {
    required: true,
  }),
};

export default class AddFileModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
