<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import Vue from 'vue';
import VueChatScroll from 'vue-chat-scroll';
import PdfCard from '@/shared/cards/PdfCard.vue';
import EditFile from '@/shared/components/EditFile.vue';

Vue.use(VueChatScroll);

const store = {
  state: {
    pdfs: [],
    loading: false,
  },
};
export default {
  components: {
    PdfCard,
    EditFile,
  },
  data() {
    return {
      store,
      file: '',
      filesLibrary: [],
      droppedLibrary: 0,
      itemSelected: null,
    };
  },
  methods: {
    ListPdfs() {
      this.itemSelected = null;
      store.state.pdfs = [];
      const listPdfsPath = '/WebOpenaiListPdf.php';
      fetch(server + listPdfsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.files && json.files.length) {
            store.state.pdfs = json.files;
          }
        });
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    previewImgsLibrary(event) {
      this.filesLibrary = [];
      if (this.droppedLibrary === 0) this.filesLibrary.push(...event.currentTarget.files);
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.filesLibrary || !this.filesLibrary.length) return;
      for (let i = 0; i < this.filesLibrary.length; i += 1) {
        readers.push(this.readAsDataURL(this.filesLibrary[i]));
      }
      Promise.all(readers).then((values) => {
        this.file = {
          name: this.filesLibrary[0].name,
          content: values,
        };
        this.uploadPdf();
      });
    },
    uploadPdf() {
      store.state.loading = true;
      const data = new FormData();
      const path = '/WebOpenaiUploadPdf.php';
      data.append('pdf', this.file.content);
      data.append('name', this.file.name);
      fetch(server + path, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.ListPdfs();
        }
      });
    },
    processPdf(pdf) {
      this.$router.push(`/file-ai/${pdf.id}/learning-cards`);
    },
    openEditFile(item) {
      this.itemSelected = item;
      const addCardWindow = document.querySelector('.add-item');
      addCardWindow.classList.toggle('show');
      if (addCardWindow.classList.value.includes('hide')) {
        addCardWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addCardWindow.classList.toggle('hide');
        }, 300);
      }
    },
  },
  beforeMount() {
    this.ListPdfs();
  },
};
</script>
<template lang="html">
  <div>
    <b-row class="chat">
      <b-col md="8" lg="9" style="margin-bottom: 24px">
        <div class="title-0-medium-28 basic-1"
             style="margin-right: 40px;display: inline-flex;align-items: center;">
          {{ $t("FILE_AI.MAIN_TITLE") }}
        </div>
      </b-col>
      <b-col  md="4" lg="3">
        <div style="float: right">
          <input type="file"
                 class="hidden"
                 accept="application/pdf"
                 id="upload-pdf"
                 @change="previewImgsLibrary" />
          <label class="btn-primary" for="upload-pdf">
            <unicon fill="#ffffff" height="24" width="24"
                    style="margin-top: -8px"
                    name="cloud-upload"/>
            {{ $t('CHAT.UPLOAD_FILE') }}
          </label>
        </div>
      </b-col>

    </b-row>

    <b-row style="margin: 0 -12.5px!important;">
      <b-col  sm="12">
        <div v-for="(item,i) in store.state.pdfs" :key="i">
          <PdfCard
              :pdf-info="item"
              :callback="openEditFile"
              :callback-params="item"
              :callback2="processPdf"
              :callback-params2="item"
          >
          </PdfCard>
        </div>
      </b-col>
    </b-row>
    <div class="add-item hide">
      <EditFile :key="this.itemSelected ?
       itemSelected.id : 0"
               :file="this.itemSelected"
                :callback="this.ListPdfs"
      >
      </EditFile>
    </div>
  </div>
</template>
<style lang="scss">
.hidden{
  display: none;
}
</style>
