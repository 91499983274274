<script>
export default {
  name: 'SelectorOption',
  props: {
    value: {
      type: Array,
    },
    option: {
      type: Object,
    },
    updateValue: {
      type: Function,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  beforeMount() {
    this.value.forEach((val) => {
      if (val.value === this.option.value) {
        this.selected = true;
      }
    });
  },
};
</script>
<template>
      <div class="option-container">
        <b-form-checkbox
          required
          v-model="this.selected"
          value="true"
          unchecked-value="false"
          v-on:change="updateValue(option,$event)"
        />
        <label class=" basic-1 headline-regular-16">
          {{ option.text }}
        </label>
      </div>
</template>
