<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddFileModel from '@/modules/file-ai/add-file-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import SelectWithCheckBox from '@/shared/components/SelectorWithCheckBox.vue';
import i18n from '../plugins/vue-i18n';

const { fields } = AddFileModel;
const store = {
  state: {
    optionsDep: [],
    optionsGroup: [],
    categories: [],
    categorySelected: '',
  },
};
export default {
  name: 'EditFile',
  components: {
    BaseButton,
    SelectWithCheckBox,
  },
  props: {
    file: {
      type: Object,
    },

    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
  },
  data() {
    return {
      fields,
      store,
      model: {},
      loading: false,
      title: '',
      id: null,
    };
  },
  methods: {
    Notify() {
      return Notify;
    },
    openAddFile() {
      const addPollWindow = document.querySelector('.add-item');
      addPollWindow.classList.toggle('show');
      if (addPollWindow.classList.value.includes('hide')) {
        addPollWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addPollWindow.classList.toggle('hide');
        }, 300);
      }
    },
    addFileFunc(e) {
      e.preventDefault();
      this.openAddFile();
      const addFilePath = '/WebOpenaiEditPdf.php';
      const data = new FormData();

      data.append('id', this.file.id);
      data.append('name', this.model.tag_name);
      if (this.model.departament) {
        data.append('department_ids', this.model.departament.map((dep) => dep.value));
      }
      if (this.model.group) {
        data.append('group_ids', this.model.group.map((dep) => dep.value));
      }

      fetch(server + addFilePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
            if (json.status === 'ok') {
              this.callback();
            }
          }
        });
    },
    getFile(id) {
      const getFilePath = '/WebOpenaiGetPdfDetails.php';
      const data = new FormData();
      data.append('file_id', id);

      fetch(server + getFilePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.name !== '') {
            this.model.tag_name = json.name;
            let temp = [];
            json.departments.forEach((item) => {
              temp.push({
                value: item.id,
                text: item.name,
              });
            });
            this.model.departament = temp;
            temp = [];
            json.groups.forEach((item) => {
              temp.push({
                value: item.id,
                text: item.name,
              });
            });
            this.model.group = temp;
          }
        });
    },
    ListBranches(parentId, parentType) {
      if (!parentType) {
        store.state.optionsDep = [];
      }
      store.state.optionsGroup = [];
      const listDepPath = '/WebListBranches.php';
      const data = new FormData();
      data.append('parent_id', parentId);
      data.append('parent_type', parentType);
      data.append('levels', 2);
      fetch(server + listDepPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.branches && json.branches.length) {
          store.state.options = json.branches;
          json.branches.forEach((branch) => {
            if (branch.type === 'department') {
              store.state.optionsDep.push({
                value: branch.id,
                text: branch.name,
              });
              branch.branches.forEach((group) => {
                if (group.type === 'group') {
                  store.state.optionsGroup.push({
                    value: group.id,
                    text: group.name,
                  });
                }
              });
            }
            if (branch.type === 'group') {
              store.state.optionsGroup.push({
                value: branch.id,
                text: branch.name,
              });
            }
          });
        }
      });
    },
    updateDep(val) {
      this.model.departament = val;
      store.state.optionsGroup = [];
      val.forEach((dep) => {
        this.ListBranches(dep.value, 'department');
      });
    },
    updateGroup(val) {
      this.model.group = val;
    },
    deletePdf(id) {
      const deletePath = '/WebOpenaiRemovePdf.php';
      const data = new FormData();
      data.append('file_id', id);
      fetch(server + deletePath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.openAddFile();
          this.callback();
        }
      });
    },
  },
  beforeMount() {
    this.model.departament = [];
    this.model.group = [];
    store.state.optionsDep = [];
    store.state.optionsGroup = [];
    this.ListBranches(0, '');
    if (this.file !== null) {
      this.id = this.file.id;
      this.getFile(this.file.id);
    }
  },
  beforeUpdate() {
    if (this.tagTitle) {
      this.title = this.tagTitle;
    }
  },
};
</script>
<template>
  <div class="add-item">
    <div class="add-item-left" v-on:click="openAddFile">
    </div>
    <div class="add-item-window" :key="this.model.tag_name">
      <form class="form" v-on:submit.prevent="(e) => {addFileFunc(e);
     }" id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.id">
          <div class="menu" :key="this.model.tag_name">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddFile"/>
            <div class="title">
              {{ this.model.tag_name }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit"
            >
              <div v-if="this.loading === true">
                <unicon fill="white" height="16" width="16" name="spinner-alt"/>
              </div>
              <div v-if="this.loading === false">
                <unicon fill="white" height="16" width="16" name="save"/>
                {{ $t('GENERAL.SAVE') }}
              </div>
            </BaseButton>
          </div>
          <div class="content">
              <b-row>
                <b-col>
                  <div class="title-2-bold-20 basic-2">
                    {{ $t('GENERAL.BASIC_INFO') }}
                  </div>
               <div class="custom-input">
                        <label>
                          {{ fields.tag_name.label }}</label>
                        <input type="text"
                               :id="fields.tag_name.name"
                               v-model="model[fields.tag_name.name]"
                               :placeholder="fields.tag_name.label"
                               required
                               maxlength="200"
                        >
                      </div>

                </b-col>
              </b-row>
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ fields.departament.label }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4" style="margin: 0 12.5px 24px 12.5px!important;">
              <b-col md="6" class="pe-0 image-answer">
                <label>
                  {{ fields.departament.label }}</label>
                <SelectWithCheckBox
                    :options="store.state.optionsDep"
                    :value="this.model.departament"
                    :update-val="updateDep"
                    :key="'dep' + this.model.departament.length"
                />
              </b-col>
              <b-col md="6" class="pe-0 image-answer"
              >
                <label>
                  {{ fields.group.label }}</label>
                <SelectWithCheckBox
                    :options="store.state.optionsGroup"
                    :value="this.model.group"
                    :key="'gr' + this.model.group.length"
                    :update-val="updateGroup"
                />
              </b-col>
            </b-row>
            <b-row style="position: absolute;bottom: 20px;
            width: -webkit-fill-available;">
              <b-col class="col" md="12">
                <BaseButton
                    :variant="'danger'"
                    :callback="deletePdf"
                    :callback-params="this.id"
                    style="width: 100%"
                >

                  <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                  {{ $t('GENERAL.DELETE') }}
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
.add-item .delete-card {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 28px;
}
</style>
